import prototypes from "./prototypes";

var screenHistory = new Array();
var screenHistoryIndex = -1;

var emulatorControlBack = null;
var emulatorControlHome = null;
var emulatorControlMenu = null;
var emulatorControlForward = null;

var deviceHomeScreenId = 0;
var deviceMenuScreenId = 0;

var screenData = null;

var emulatorImageHost = 'https://d35v9wsdymy32b.cloudfront.net/v3';
var emulatorDataHost = 'https://static.customersaas.com/t-mobile-us-emulator/data';


export class Emulator {
    constructor() {
        this.selector = null;
        this.element = null;
        this.deviceViewport;
        this.templates = {
            emulator: require("./templates/emulator.handlebars")
        }
        this.config = {};
    }
    init(config) {
        this.selector = config.selector;
        this.element = document.querySelector(this.selector);
        this.config = config;
        let emulator = this.templates.emulator();
        this.element.innerHTML = emulator;
        this.loadEmulator(this.config.locale, this.config.deviceID);
    }
    historyAppend(id) {
        screenHistory = screenHistory.slice(0, screenHistoryIndex + 1);
        if (screenHistory.length > 0 && screenHistory[screenHistoryIndex] == id) {
            return;
        }
        screenHistoryIndex++;
        screenHistory.push(id);
    }

    historyBack() {
        if (screenHistoryIndex > 0) {
            screenHistoryIndex--;
            var id = screenHistory[screenHistoryIndex];
            return id;
        }
        return 0;
    }

    historyForward() {
        if (screenHistoryIndex < screenHistory.length - 1) {
            screenHistoryIndex++;
            var id = screenHistory[screenHistoryIndex];
            return id;
        }
    }

    emulatorUpdateHistoryControls() {
        emulatorControlBack.setAttribute('data-disabled', screenHistoryIndex > 0 ? 'false' : 'true');
        emulatorControlForward.setAttribute('data-disabled', screenHistoryIndex < screenHistory.length - 1 ? 'false' : 'true');
    }

    loadEmulator(languageId, deviceId) {
        const _that = this;
        this.deviceViewport = this.element.querySelector('.emulator-device-viewport');
        this.element.querySelector('.wrapper').setAttribute('data-device-id', deviceId);

        var screenRequest = new XMLHttpRequest();
        screenRequest.open('GET', emulatorDataHost + '/' + languageId + '/' + deviceId + '/screens.json', true);

        screenRequest.onload = function () {
            if (this.status >= 200 && this.status < 400) {
                var data = JSON.parse(this.response);

                screenData = data;

                deviceHomeScreenId = data.device.home;
                deviceMenuScreenId = data.device.menu;

                // If we don't have a screen ID for the menu, we hide the menu button
                emulatorControlMenu.setAttribute('data-hidden', data.device.menu == 0 ? 'true' : 'false');

                _that.deviceViewport.style.height = data.device.height + 'px';
                _that.element.querySelector('#device').style.height = data.device.height + 'px';

                _that.navigateHome();
                _that.emulatorUpdateHistoryControls();
            }
        };

        screenRequest.send();

        emulatorControlBack = this.element.querySelector('.emulator-control-back');
        emulatorControlBack.addEventListener("click", e=>{
            e.preventDefault();
            this.navigateBack();
            return false;
        });

        emulatorControlHome = this.element.querySelector('.emulator-control-home');
        emulatorControlHome.addEventListener("click", e=>{
            e.preventDefault();
            this.navigateHome();
            return false;
        });

        emulatorControlMenu = this.element.querySelector('.emulator-control-menu');
        emulatorControlMenu.addEventListener("click", e=>{
            e.preventDefault();
            this.navigateMenu();
            return false;
        });

        emulatorControlForward = this.element.querySelector('.emulator-control-forward');
        emulatorControlForward.addEventListener("click", e=>{
            e.preventDefault();
            this.navigateForward();
            return false;
        });
    }

    actionClick(e) {
        var id = e.target.getAttribute('data-target-id');
        if (id > 0) {
            this.showScreen(id);
            this.historyAppend(id);
            this.emulatorUpdateHistoryControls();
        }
    }

    showScreen(id) {
        var screen = screenData.device.screens[id];

        var img = new Image();
        img.src = emulatorImageHost + '/devices/' + screenData.device.id + '/screens/' + screen.image;

        // Remove screens
        var screens = this.element.querySelectorAll('.image');
        for (var i = 0; i < screens.length; i++) {
            screens[i].parentNode.removeChild(screens[i]);
        }

        // Create new screen
        var screenImage = document.createElement('div');
        screenImage.className = 'image';
        screenImage.setAttribute('data-device', screenData.device.id);
        screenImage.setAttribute('data-screen', id);
        screenImage.style.width = screenData.device.width + 'px';
        screenImage.style.height = screen.height + 'px';
        screenImage.style.display = 'block';
        screenImage.style.backgroundImage = 'url("' + emulatorImageHost + '/devices/' + screenData.device.id + '/screens/' + screen.image + '")';

        // Add click boxes to new screen
        for (var i = 0; i < screen.actions.length; i++) {
            var action = document.createElement('div');
            action.className = 'action';
            action.style.left = screen.actions[i][1] + 'px';
            action.style.top = screen.actions[i][2] + 'px';
            action.style.width = screen.actions[i][3] + 'px';
            action.style.height = screen.actions[i][4] + 'px';
            action.setAttribute('data-action-id', screen.actions[i][0]);
            action.setAttribute('data-target-id', screen.actions[i][5]);
            action.addEventListener("click", e => {
                this.actionClick(e);
            })
            screenImage.appendChild(action);
        }

        this.deviceViewport.appendChild(screenImage);

        // Preload images that new screen links to in the background to prevent a loading delay when navigating to a new screen
        setTimeout(this.preloadScreens.bind(null, id), 500);
    }

    preloadScreens(id) {
        var screen = screenData.device.screens[id];

        for (var i = 0; i < screen.actions.length; i++) {
            var targetId = screen.actions[i][5];
            var target = screenData.device.screens[targetId];
            if (targetId != 0 && targetId in screenData.device.screens) {
                var img = new Image();
                img.src = emulatorImageHost + '/devices/' + screenData.device.id + '/screens/' + target.image;
            }
        }
    }

    navigateHome() {
        this.showScreen(deviceHomeScreenId);
        this.historyAppend(deviceHomeScreenId);
        this.emulatorUpdateHistoryControls();
    }

    navigateMenu() {
        this.showScreen(deviceMenuScreenId);
        this.historyAppend(deviceMenuScreenId);
        this.emulatorUpdateHistoryControls();
    }

    navigateBack() {
        var id = this.historyBack();
        if (id > 0) {
            this.showScreen(id);
            this.emulatorUpdateHistoryControls();
        }
    }

    navigateForward() {
        var id = this.historyForward();
        if (id > 0) {
            this.showScreen(id);
            this.emulatorUpdateHistoryControls();
        }
    }



    ready(callback) {
        document.ready().then(async () => {
            if (callback) callback();
        });
    }
}